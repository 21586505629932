import { Link } from "react-router";
import { cn } from "~/lib/utils";

/**
 * Layout for supporting pages.
 */
export function UtilLayout({ children }: { children: React.ReactNode }) {
  return (
    <div className="h-full w-full flex flex-col">
      <div className="grow flex flex-col">
        <div className="grow flex flex-col items-center justify-center px-4 py-16 max-w-[50rem] max-h-[50rem] mx-auto">
          <Link to="/" className="flex mb-8">
            <img src="/images/logo-primary-32.svg" alt="Sumcast" />
          </Link>
          <div className="mb-8 text-center font-serif text-lg border-t border-b border-divider p-4 tracking-wide">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}
